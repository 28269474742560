$henley-blue: #405363;
$henley-gray: #75767A;
$secondary-gray: #c1d3e0;
$light-gray: #C5C6C8;
$gray: #6F7072;
$dark-gray: #4B4B4D;
$background-colour: #f7f7f7;
$white: #fff;

:root {
    --color-app-henley-blue: #{$henley-blue};
    --color-app-henley-gray: #{$henley-gray};
    --color-app-secondary-gray:#{$secondary-gray};
    --color-app-light-gray: #{$light-gray};
    --color-app-gray: #{$gray};
    --color-app-dark-gray: #{$dark-gray};
    --color-app-background-color: #{$background-colour};
    --color-app-white: #{$white};
}

[data-top20-embed] {
    scroll-behavior:smooth;
    background-color: #fff;


    .appContainer{
        max-width: 1800px;
        margin: 0 auto;
        padding: 16px;
    }
    .top-title{
  
        margin-bottom: 30px;
    }


    .no-border-bottom{
        border-bottom:none!important;
    }

    .no-border-right{
        border-right:none!important;
    }

    .font-weight-600{
        font-weight: 600!important;
    }

    .custom-radiobox-group{
        display: flex;
        flex-direction: row;
        align-items: stretch;
        border:1px solid var(--color-app-henley-blue);
        padding:4px;
        background-color: #fff;
        justify-content: space-between;
        max-width: 320px;
    }

    .custom-radiobox{
        position: relative;
        display: block;
        font-size: 12px;
        letter-spacing: 1.14px;
        text-transform: uppercase;
        line-height: 1.4;
        margin-bottom: 0;
        color:var(--color-app-henley-blue);
        text-align: center;
        min-width: 84px;
        // flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        font-family: "Roboto Regular";
      
       
        input{
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .label-text{
           display: flex;
           padding: 12px;
           cursor: pointer;
           justify-content: center;
           align-items: center;
           height: 100%;

           .circle{
               margin-right: 5px;
           }
        }

        &:hover input ~ .label-text {
            background-color: var(--color-app-background-color);
        }

        input:checked ~ .label-text{
            background-color: var(--color-app-henley-blue);
            color: #fff;
        }
          
    }

    .text-underline{
        text-decoration: underline;
    }

    .footer-note{
        font-size: 12px;
        line-height: 1.2;
        color:  var(--color-app-dark-gray);
        font-family: Roboto Regular;
        margin-bottom: 50px;

        ul,li,p{
            font-size: inherit;
        }

        ul{
            list-style-type: disc;
            margin-left: 20px;
            li{
                margin-bottom: 2px;
                padding: 0;
                
                &:before{
                    display: none;
                }
            }
        }
    }

    h5.field{
        font-family: Roboto Regular;
        color: var(--color-app-dark-gray);
        text-transform: initial;
        font-size: 10px;
    }

    .chartTable{

        font-family: Garamond Regular;
        width: 100%;

        th{
            font-size: 12px;
            padding: 8px;
            font-family: Roboto Regular;
            // color: var(--color-app-henley-blue);
            font-weight: 400;
            vertical-align: bottom;
        }

        td{
            line-height: 1.1;
            // color: var(--color-app-henley-gray);
            border-right: 1px solid #C1C5C8;
            font-size: 20px;
            @media (max-width: 799px){
                font-size: 16px;
                // border-right: none;
                &:first-child{
                    border-right: 1px solid #C1C5C8;
                }
            }
        }

        th,
        td{
            border-bottom: 1px solid #C1C5C8;
            // padding: 10px 30px;
            padding: 10px;
            color: var(--color-app-henley-blue);

            &:first-child{
                padding-left: 0;
            }

            &:last-child{
                border-right: none;
            }
        }
        
   
    }
  
    .mobileMenuWrapper{
        position: relative;
        @media (max-width:799px){
            margin-bottom: 40px;
            .custom-radiobox-group{
                display: none;
                flex-direction: column;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 100;
                .custom-radiobox{
                    text-align: left;
                    .label-text{
                        justify-content: flex-start;
                    }
                }
            }

            .mobileMenuToggle{
                border:1px solid var(--color-app-henley-blue);
                font-size: 12px;
                font-family: "Roboto Regular";
                text-transform: uppercase;
                background: var(--color-app-henley-blue);
                color: #fff;
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width:100%;
                padding: 0;
                .mobileMenuTitleText{
                    padding: 0 18px;
                }
                .dropdownIcon{
                    width: 50px;
                    height: 50px;
                    flex: 0 0 50px;
                    background: #fff;
                    margin-right: 0;
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border:1px solid var(--color-app-henley-blue);
                    border-right: 0;

                    svg{
                        transition: transform 0.25s ease-in-out;
                    }
                }
            }

            &.menuOpen{
                .custom-radiobox-group{
                    display: block;
                }

                .mobileMenuToggle{
                    svg{
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        @media (min-width:800px){
            .mobileMenuToggle,
            .dropdownIcon{
                display: none;
            }
        }
    }
    
}
